<template>
  <div>
    <el-form :model="form"
             ref="formRef"
             label-width="100px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :span="6">
          <el-form-item label="学校">
            <el-select style="width:100%"
                       v-model="form.school_id"
                       clearable
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in schoolList"
                         :key="item.school_id"
                         :label="item.school_name"
                         :value="item.school_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="起始时间">
            <el-date-picker v-model="form.timeBegin"
                            type="datetime"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.timeEnd"
                            type="datetime"
                            style="width:100%"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button type="primary"
                     @click="search">提交</el-button>
          <el-button type="primary"
                     @click="exportExcel">导出</el-button>
        </el-col>
      </el-row>
    </el-form>

    <Table ref="TablesRef"
           :tableData="tableData" />

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />

  </div>
</template>

<script>
import { now_month_time, nowDateTime } from '@/utils/date.js'
export default {
  data () {
    return {
      tableData: [],
      value1: '',
      form: {
        school_id: '',
        timeBegin: now_month_time(),
        timeEnd: nowDateTime()
      },
      schoolList: [],
      all_sum: {},
      page: 1,
      size: 10,
      totalElements: 0,
      pageName: 'statics_testpaperStatics_admin',
      tableColumns: [
        { prop: 'edu_school_name', align: 'center', label: '所在学校名', width: '', showToopic: false },
        { prop: 'edu_user_id', align: 'center', label: '教师ID', width: '', showToopic: true },
        { prop: 'edu_user_name', align: 'center', label: '用户名', width: '', showToopic: false },
        { prop: 'subject_name', align: 'center', label: '科目', width: '', showToopic: false },
        { prop: 'paper_count', align: 'center', label: '提交教师试卷数', width: '', showToopic: false },
        { prop: 'user_paper_count', align: 'center', label: '学生总做卷次数', width: '', showToopic: false },
        { prop: 'user_score_rate', align: 'center', label: '学生得分率', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '200', type: 'img', btns: [
            { content: '教师详细', type: 'look', event: 'showForm' },
          ]
        },
      ]
    }
  },
  mounted () {
    this.getSchoolList()
    this.setTablesColums()

  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/stats/cp',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    getSchoolList () {
      this.$http({
        url: '/api/v2/public/enabled_school',
        method: 'get'
      }).then(res => {
        this.schoolList = res.data.list
      })
    },
    exportExcel () {
      this.$http({
        url: '/api/v2/stats/cp_export',
        method: 'get',
        responseType: "blob",
        params: this.form
      }).then(res => {
        const a = document.createElement("a")
        a.href = window.URL.createObjectURL(res.data)
        let strtime = this.form.timeBegin

        a.download = `export-start${strtime}.xlsx`
        a.click()
      })
    },
    showForm (row) {
      let form = {
        timeEnd: this.form.timeEnd,
        timeBegin: this.form.timeBegin,
        edu_user_id: row.edu_user_id,
        subject_id: row.subject_id
      }
      this.$router.push(`/testpaper_statics/teacherInfo?form=${JSON.stringify(form)}`)
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>