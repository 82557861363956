// 年月日时分秒
export const nowDateTime = function () {
  // 获取当前时间
  let date = new Date()
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds()}`
}
// 年月日 时分秒为0 的前7天
export const nowDay = function () {
  let date = new Date()
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`
}
// 当前日期的前七天
export const frontDay = function (n) {
  var d = new Date();
  var year = d.getFullYear();
  var mon = d.getMonth() + 1;
  var day = d.getDate();
  if (day <= n) {
    if (mon > 1) {
      mon = mon - 1;
    }
    else {
      year = year - 1;
      mon = 12;
    }
  }
  d.setDate(d.getDate() - n);
  year = d.getFullYear();
  mon = d.getMonth() + 1;
  day = d.getDate();
  var s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
  return `${s} 00:00:00`;
}
// 当前日期的当天截止
export const frontNowDay = function (n) {
  var d = new Date();
  var year = d.getFullYear();
  var mon = d.getMonth() + 1;
  var day = d.getDate();
  if (day <= n) {
    if (mon > 1) {
      mon = mon - 1;
    }
    else {
      year = year - 1;
      mon = 12;
    }
  }
  d.setDate(d.getDate() - n);
  year = d.getFullYear();
  mon = d.getMonth() + 1;
  day = d.getDate();
  var s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
  return `${s} 23:59:59`;
}
// 当月第一天
export const now_month_time = function () {
  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  return `${year}-${month}-01 00:00:00`
}